import React, { useEffect, useState } from 'react'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FcCheckmark } from 'react-icons/fc';
import { numberFormat } from '../components/numberFormate';
import { fetchDataFromApi } from '../utils/api';
import { addWeeks } from '../components/DateFormate';
import TagManager from 'react-gtm-module';
import { useCart } from 'react-use-cart';
import { BsFacebook } from 'react-icons/bs';
import { AiFillTwitterCircle, AiOutlineInstagram, AiOutlineYoutube } from 'react-icons/ai';
import { FaLinkedin, FaPinterestP } from 'react-icons/fa';

const OrderConfirmation = () => {
    const orderid = localStorage.getItem("orderid");
    const [orderc, setOrderc] = useState([]);
    const [orders, setOrders] = useState([]);
    const date = new Date();
    const newdate = addWeeks(date, 1);
    const pathname = window.location.href;
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/product/";

    const {
        items,
        cartTotal,
        emptyCart,
    } = useCart();
    useEffect(() => {
        getConfirmOrderDetails();
    }, []);

    const getConfirmOrderDetails = () => {
        fetchDataFromApi(`get-confirm-order-details/${orderid}`).then((res) => {
            if (res.status === 1) {
                setOrderc(...[res.orderdata]);
                setOrders(...[res.orders]);
                TagManager.dataLayer({
                    dataLayer: {
                        event: "purchase",
                        pagePath: pathname,
                        eventModel: {
                            transaction_id: orderc.orderid,
                            value: cartTotal,
                            tax: 0.00,
                            shipping: 0.00,
                            currency: "INR",
                            coupon: "",
                            items
                        }
                    },
                });
                emptyCart();
            }
        });
    }
    let gtag = {
        'event': 'conversion',
        'send_to': 'AW-11216573566/nE49CMu_2KIZEP6oveQp',
        'transaction_id': ''
    }
    return (
        <div className="orderConfirmation">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Thank You</title>
                <link rel="canonical" href={pathname} />
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(gtag) }}
                />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page">
                                <span >Home</span>
                            </Link>
                        </li>
                        <li className="items product"><span>Thank You</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12 mb-4 orderMobile">
                <ContentWrapper>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="orderConfirms">
                                <div className="orderHeader">
                                    <h3>Greetings Dear <b>{orderc.order_name}</b></h3>
                                    <div>
                                        <span>Total Amount </span>
                                        <span><b>{numberFormat(orderc.totalprice)}</b></span>
                                    </div>
                                    <div>
                                        <span>Order Confirmation #</span>
                                        <span><b>{orderc.orderid}</b></span>
                                    </div>
                                </div>
                                <div className="orderBody">
                                    <FcCheckmark />
                                    <h2>Thank You For Your Order!</h2>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="orderBodyBottom">
                                                <b>Delivery Address</b>
                                                <p>Mobile:{orderc.order_mobile}</p>
                                                <p>Address:{orderc.order_address}</p>
                                                <p>Landmark:{orderc.order_landmark}</p>
                                                <p>City:{orderc.order_city}</p>
                                                <p>State:{orderc.order_state}</p>
                                                <p>Pincode:{orderc.order_zipcode}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className="orderDetail">
                                                <b>Estimated Delivery Date</b>
                                                <p>{newdate.toLocaleString()}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 table-responsive">
                                    <table className="table table-bordered ordertable">
                                        <thead>
                                            <tr className="oderhead">
                                                <th>#</th>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Price</th>
                                                <th>QTY</th>
                                                <th>Sub Total</th>
                                            </tr>
                                        </thead>
                                        <tbody className="ordertbody">
                                            {orders?.map((orders, index) => (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td><div className="orderoimg"><img src={BASE_URL + orders?.productimage} alt={orders?.productimage} /></div></td>
                                                    <td>{orders?.productname}</td>
                                                    <td>{numberFormat(orders?.productprice)}</td>
                                                    <td>{orders?.qty}</td>
                                                    <td>{numberFormat(orders?.subtotal)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="orderFooter">
                                    <div className="orderFooterDetails"></div>
                                    <h3 className="mb-4">Get upto 44% off on our exclusive collections.</h3>
                                    <Link className="shopNow" to="/products">Shop Now</Link>
                                    <div className="orderFooterBootm mt-4">
                                        <p>If you wish to know more about our seasonal promotions and discounts, call us now at: <Link to="tel:+91-9319728256">+91-9319728256.</Link></p>
                                    </div>
                                    <ul className="sociallink" style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
                                        <li><Link aria-label="facebook" title="facebook" name="facebook" to="https://www.facebook.com/SatKartarGroup/" target="_blank"><BsFacebook /></Link></li>
                                        <li><Link aria-label="twitter" title="twitter" name="twitter" to="https://twitter.com/SKShoppingLtd" target="_blank"><AiFillTwitterCircle /></Link></li>
                                        <li><Link aria-label="linkedin" title="linkedin" name="linkedin" to="https://www.linkedin.com/company/sat-kartar-shopping-private-limited" target="_blank"><FaLinkedin /></Link></li>
                                        <li><Link aria-label="youtube" title="youtube" name="youtube" to="https://www.youtube.com/@satkartarofficial/" target="_blank"><AiOutlineYoutube /></Link></li>
                                        <li><Link aria-label="pinterest" title="pinterest" name="pinterest" to="https://in.pinterest.com/SatKartarOfficial/" target="_blank"><FaPinterestP /></Link></li>
                                        <li><Link aria-label="instagram" title="instagram" name="instagram" to="https://www.instagram.com/satkartarofficial/" target="_blank"><AiOutlineInstagram /></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default OrderConfirmation
